<template>
  <!-- 发票信息和邮寄信息 -->
  <div>
    <navBar :title="title"></navBar>

    <ul class="info_list">
      <li @click="add()">
        <span @click="add()">添加</span>
        <van-icon name="add-o" />
      </li>
      <van-radio-group v-model="radio">
        <van-radio
          :key="index"
          :name="index"
          @click="changeRadio(index)"
          checked-color="#ccc"
          v-for="(item,index) in infos"
        >
          <div class="del">
            <div>{{item.name}}</div>
            <div>
              <span @click.stop="edit(item,index)">修改</span>
              <span @click.stop="dele(index,item.id)">删除</span>
            </div>
          </div>
        </van-radio>
      </van-radio-group>

      <!-- <van-radio-group v-if="navTitle=='mail'" v-model="radio2">
        {{navTitle}}
        <van-radio
          :key="index"
          :name="index"
          @click="changeRadio(index)"
          checked-color="#ccc"
          v-for="(item,index) in infos"
        >
          <div class="del">
            <div>{{item.name}}</div>
            <div>
              <span @click.stop="edit(item,index)">修改</span>
              <span @click.stop="dele(index,item.id)">删除</span>
            </div>
          </div>
        </van-radio>
      </van-radio-group>-->
      <!-- <li :key="index" v-for="(item,index) in infos"></li> -->
    </ul>
  </div>
</template>

<script>
import navBar from '../navBar'
import { Dialog } from 'vant';
export default {
  name: '',
  components: {
    navBar
  },
  data () {
    return {
      title: {
        name: '',
        status: true
      },
      infos: [],//发票/邮寄信息
      // radio:null
    }
  },
  watch: {

    '$route' (to, from) {
      if (this.$store.state.navTitle == 'bill') {
        this.title.name = "发票信息"
        this.billData()
      } else if (this.$store.state.navTitle == "mail") {
        this.title.name = "邮寄信息"
        this.mailData()
      }
    }
  },
  created () {

    console.log('和哦哦了', this.radio);
    if (this.$store.state.navTitle == 'bill') {
      this.title.name = "发票信息"
      this.billData()
    } else if (this.$store.state.navTitle == "mail") {
      this.title.name = "邮寄信息"
      this.mailData()
    }

  },
  computed: {
    navTitle: {
      get () {
        return this.$store.state.navTitle
      },
      set (value) {
        return value;
      }

    },
    // radio1: {
    //   get () {
    //     return this.$store.state.billIndex
    //   },
    //   set (value) {
    //     return value;
    //   }

    // },
    // radio2: {
    //   get () {
    //     return this.$store.state.mailIndex
    //   },
    //   set (value) {
    //     return value;
    //   }

    // },
    radio: {
      get () {
        if (this.navTitle == 'bill') {
          return this.$store.state.billIndex

        } else if (this.navTitle == "mail") {
          return this.$store.state.mailIndex

        }
      },
      set (value) {
        return value;
      }
    }

  },
  methods: {
    // 获取企业信息
    billData () {

      this.$request.get(this.$api.billInfo, { id: this.$store.state.userId }).then(res => {
        console.log('hhhhhhhhhh', res);
        this.infos = res.data

      })
    },
    // 获取邮寄信息
    mailData () {
      this.$request.get(this.$api.billAddress, { id: this.$store.state.userId }).then(res => {
        this.infos = res.data
      })
    },
    // 添加
    add () {
      if (this.title.name == "发票信息") {
        this.$router.push({
          name: 'add',
          params: { status: true }
        })
      } else if (this.title.name == "邮寄信息") {
        this.$router.push({
          name: 'add',
          params: { status: false }
        })
      }
    },
    changeRadio (index) {

      if (this.$store.state.navTitle == 'bill') {
        this.radio1 = index
        this.$store.state.billIndex = index;
        sessionStorage.setItem('billIndex', index);
      } else if (this.$store.state.navTitle == "mail") {
        this.radio2 = index

        this.$store.state.mailIndex = index;
        sessionStorage.setItem('mailIndex', index);
      }

    },
    // 修改
    edit (item, index) {
      this.radio = index;

      console.log('this.radio', this.radio);

      if (this.title.name == "发票信息") {
        this.$store.state.billIndex = index;
        sessionStorage.setItem('billIndex', index);
        this.$router.push({
          name: 'change',
          params: item
        })
      } else if (this.title.name == "邮寄信息") {
        this.$store.state.mailIndex = index;
        sessionStorage.setItem('mailIndex', index);
        this.$router.push({
          name: 'change',
          params: item
        })
      }
    },
    // 删除
    dele (index, id) {

      if (this.title.name == "发票信息") {
        Dialog.confirm({
          title: '提示',
          message: '确认删除?',
        })
          .then(() => {
            console.log('1');
            if (this.radio == index) {
              this.radio = 0;
              this.$store.state.billIndex = 0;
              sessionStorage.setItem('billIndex', 0);
            }
            this.$request.delete(this.$api.delInfo + '/' + id).then(res => {
              console.log('vbvb', res);
              this.billData()

            })
          })
      } else if (this.title.name == "邮寄信息") {
        Dialog.confirm({
          title: '提示',
          message: '确认删除?',
        })
          .then(() => {
            console.log('1');
            this.radio = 0;
            this.$store.state.mailIndex = 0;
            sessionStorage.setItem('mailIndex', 0);
            this.$request.delete(this.$api.delAddress + '/' + id).then(res => {
              console.log('vbvb', res);
              this.mailData()
            })
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.info_list {
  li {
    height: 35px;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    display: flex;
    align-items: center;
    span {
      margin-right: 8px;
    }
  }
}
/deep/.van-radio {
  border-bottom: 1px solid #ccc;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;

  > .van-radio__label {
    width: 100%;
    padding-right: 10px;
    font-size: 14px;
    .del {
      display: flex;
      justify-content: space-between;

      > div {
        &:nth-child(2) {
          // span
          width: 80px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
